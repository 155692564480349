<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="חיפוש"
            single-line
            icon
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="d-flex justify-space-around align-center">
          <v-btn icon color="success" @click="addCompany">
            <v-icon color="success">mdi-plus</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="loadData">
            <v-icon color="primary">mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="companies"
      :search="search"
      item-key="email"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <v-icon color="green" @click="editCompany(item)">mdi-pencil</v-icon>
          <v-icon color="red" @click="deleteCompany(item)">mdi-delete</v-icon>
        </div>
      </template>
    </v-data-table>
    <CompanyHandler
      v-if="openDialog"
      v-model="openDialog"
      :isNew="isNew"
      :company="company"
    />
  </v-card>
</template>

<script>
import CompanyHandler from "@/components/dialogs/CompanyHandler.vue";
import { createSwal } from "@/actions.js";

export default {
  name: "AdminCompanyTable",
  components: { CompanyHandler },

  data() {
    return {
      openDialog: false,
      isNew: null,
      company: null,
      search: "",
      loading: false,
      headers: [
        {
          text: "שם החברה",
          align: "start",
          value: "name",
        },
        {
          text: "טלפון",
          align: "start",
          value: "phone",
        },
        { text: "פרטי בנק", value: "bankDetails" },
        { text: "אימייל", value: "email" },
        { text: "כתובת", value: "address" },
        { text: "עיר", value: "city" },
        { text: "פעולות", value: "actions", align: "end" },
      ],
    };
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("company/index");
      this.loading = false;
    },
    async editCompany(company) {
      this.company = company;
      this.isNew = false;
      this.openDialog = true;
    },
    async addCompany() {
      this.isNew = true;
      this.openDialog = true;
    },

    async deleteCompany(company) {
      let { isConfirmed } = await createSwal({
        title: `למחוק את ${company.name}?`,
        icon: "question",
        confirmColor: "red",
      });
      if (isConfirmed)
        await this.$store.dispatch("company/destroy", company._id);
    },
  },
  async mounted() {
    if (!this.companies.length) {
      await this.$store.dispatch("company/index");
    }
  },

  computed: {
    companies() {
      return this.$store.getters.companies;
    },
  },
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
}
</style>

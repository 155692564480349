<template>
  <my-dialog
    v-model="openModal"
    :title="`${isNew ? 'יצירת חברה' : 'עדכון חברה'}`"
  >
    <template v-slot:content>
      <v-row>
        <v-col md="4" cols="12" align="center" justify="center">
          <v-text-field v-model="form.name" label="שם החברה" />
        </v-col>
        <v-col md="4" cols="12" align="center" justify="center">
          <v-text-field v-model="form.phone" label="טלפון" />
        </v-col>
        <v-col md="4" cols="12" align="center" justify="center">
          <v-text-field v-model="form.address" label="כתובת" />
        </v-col>
        <v-col md="4" cols="12" align="center" justify="center">
          <v-combobox :items="cities" v-model="form.city" label="עיר" />
        </v-col>
        <v-col md="4" cols="12" align="center" justify="center">
          <v-text-field v-model="form.email" label="אימייל" />
        </v-col>
        <v-col md="4" cols="12" align="center" justify="center">
          <v-text-field v-model="form.bankDetails" label="פרטי בנק" />
        </v-col>
        <v-col md="4" cols="12" align="center" justify="center">
          <v-text-field v-model="form.payLink" label="קישור לתשלום" />
        </v-col>
        <v-col md="4" cols="12" align="center" justify="center">
          <v-text-field v-model="form.password" label="סיסמה" />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" justify="center" class="red--text">
          {{ errorMessages.toString() }}
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn @click="submit" v-text="'שמור'" color="success" />
    </template>
  </my-dialog>
</template>
<script>
import MyDialog from "../generics/MyDialog.vue";
export default {
  name: "CompanyHandler",
  components: { MyDialog },
  props: {
    value: Boolean,
    company: { type: Object, default: () => ({}) },
    isNew: Boolean,
  },
  data() {
    return {
      form: {
        name: null,
        phone: null,
        address: null,
        city: null,
        email: null,
        bankDetails: null,
        password: null,
      },
      errorMessages: [],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v?.length >= 6 || "מינימום 6 תווים",
      },
    };
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    cities() {
      return this.$store.getters.cities;
    },
  },
  methods: {
    validator() {
      let isValid = true;
      // const errorMessages = []
      if (!this.form.name?.length) {
        this.errorMessages.push(" שם החברה נדרש");
        isValid = false;
      }
      if (!this.form.phone?.length) {
        this.errorMessages.push(" פלאפון נדרש");
        isValid = false;
      }
      if (!this.form.email?.length) {
        this.errorMessages.push(" אימייל נדרש");
        isValid = false;
      }
      if (!this.form.city?.length) {
        this.errorMessages.push(" עיר נדרשת");
        isValid = false;
      }
      if (this.isNew && !this.form.password?.length) {
        this.errorMessages.push(" סיסמה נדרשת");
        isValid = false;
      }
      return isValid;
    },
    createErrorMessage() {
      setTimeout(() => {
        this.errorMessages = [];
      }, 3000);
    },
    async submit() {
      if (!this.validator()) {
        this.createErrorMessage();
        return;
      }
      try {
        const action = this.isNew ? "store" : "update";
        await this.$store.dispatch(`company/${action}`, this.form);
        this.openModal = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    if (!this.isNew) {
      this.form = { ...this.form, ...this.company };
    }
  },
};
</script>

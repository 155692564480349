<template>
  <v-container class="d-flex justify-center flex-column pa-10">
    <AdminCompanyTable />
  </v-container>
</template>

<script>
import AdminCompanyTable from "@/components/tables/AdminCompanyTable.vue";

export default {
  name: "AdminCompanyManager",
  components: { AdminCompanyTable },

  mounted() {
    this.$store.commit("title/set", "ניהול חברות");
  },
  beforeDestroy() {
    this.$store.commit("title/set", "");
  },
};
</script>
<style lang="scss" scoped></style>
